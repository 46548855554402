<template>
  <nav class="x-header navbar navbar-expand-lg -anon">
    <div class="container-fluid align-items-center h-100 position-relative">
      <div id="headerBrand">
        <button
          v-b-toggle.sidebar-right
          type="button"
          class="btn bg-transparent p-0 x-hamburger js-hamburger-toggle"
        >
          <span />
          <span />
          <span />
        </button>
        <div class="-branding-inner-wrapper">
          <a
            routerlink="/"
            class="navbar-brand"
            href="/"
          ><img
            alt=" รวมคาสิโนออนไลน์ บาคาร่าสด สล็อต กีฬา และเครดิตฟรี"
            class="-logo"
            :src="logo_paths"
          ></a>
        </div>
      </div>
      <div id="headerContent">
        <ul class="nav -menu-wrapper">
          <li class="nav-item ng-star-inserted">
            <router-link
              routerlinkactive="-active"
              :to="{ name: 'lucky-wheel' }"
              :class="{ '-active': $route.name === 'lucky-wheel' }"
              class="nav-link -promotion"
            >
              <div class="-img-wrapper"><img
                src="@/assets/newImg/wheel.png"
                alt="Menu icon promotion"
                width="30"
                height="30"
                class="-icon"
              ></div>
              <div class="-text">
                หมุนกงล้อ
              </div>
            </router-link>
          </li>
          <!-- <li class="nav-item ng-star-inserted">
            <router-link
              routerlinkactive="-active"
              :to="{ name: 'deposit-fixed' }"
              :class="{ '-active': $route.name === 'deposit-fixed' }"
              class="nav-link -promotion"
            >
              <div class="-img-wrapper"><img
                src="@/assets/newImg/depo.png"
                alt="Menu icon promotion"
                width="30"
                height="30"
                class="-icon"
              ></div>
              <div class="-text">
                ฝากประจำ
              </div>
            </router-link>
          </li> -->
          <li class="nav-item ng-star-inserted">
            <router-link
              routerlinkactive="-active"
              :to="{ name: 'invite-friend' }"
              :class="{ '-active': $route.name === 'invite-friend' }"
              class="nav-link -promotion"
            >
              <div class="-img-wrapper"><img
                src="@/assets/newImg/affiliate.png"
                alt="Menu icon promotion"
                width="30"
                height="30"
                class="-icon"
              ></div>
              <div class="-text">
                สร้างรายได้
              </div>
            </router-link>
          </li>
          <li class="nav-item ng-star-inserted">
            <router-link
              routerlinkactive="-active"
              class="nav-link -promotion"
              :class="{ '-active': $route.name === 'promotions' }"
              :to="{ name: 'promotions' }"
            >
              <div class="-img-wrapper"><img
                src="@/assets/newImg/ic-header-menu-promotion.png"
                alt="Menu icon promotion"
                width="30"
                height="30"
                class="-icon"
              ></div>
              <div class="-text">
                โปรโมชั่น
              </div>
            </router-link>
          </li>
        </ul>
        <div class="navbar-nav ng-star-inserted">
          <div class="x-logged">
            <div class="-deposit-container d-none d-lg-block">
              <router-link
                class="text-white js-account-approve-aware btn -btn-deposit"
                :to="{ name: 'deposit' }"
              >
                <div class="f-7">
                  ฝากเงิน
                </div>
              </router-link>
            </div>
            <div class="-withdraw-container d-none d-lg-block">
              <router-link
                class="text-white js-account-approve-aware btn -btn-withdraw"
                :to="{ name: 'withdraw' }"
              >
                <div class="f-7">
                  ถอนเงิน
                </div>
              </router-link>
            </div>
            <div class="-profile-container">
              <div class="d-none d-lg-block">
                <div class="-btn-wrapper">
                  <div class="-inner-wrapper">
                    <a
                      class="text-decoration-none"
                    >
                      <div class="-profile-name">{{ User.username }}</div>
                    </a>
                    <div class="-balance-container">
                      <div class="-text-username">
                        {{ User.username }}
                      </div>
                      <div class="-user-balance js-user-balance f-sm-6 f-7">
                        <div class="-inner-box-wrapper">
                          <img
                            src="@/assets/newImg/ic-coin.png"
                            alt="customer image"
                            width="26"
                            height="21"
                            class="img-fluid -ic-coin"
                          ><span id="customer-balance"><a
                            class="-amount"
                            href="/wallet"
                          > {{
                            Number(Math.abs(balance))
                              .toFixed(2)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ','),

                          }} </a></span>
                        </div><button
                          id="btn-customer-balance-reload"
                          type="button"
                          class="-btn-balance"
                        >
                          <i class="fas fa-sync-alt f-9" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <router-link :to="{ name: 'profile' }">
                    <div class="x-profile-image"><img
                      src="@/assets/newImg/profile.jpg"
                      alt="profile"
                      width="40px"
                      height="40px"
                      class="rounded-circle border border-2"
                      style="vertical-align: initial;"
                    ></div>
                  </router-link>
                </div>
              </div>
            </div>
            <div class="-profile-container">
              <div class="js-ez-logged-sidebar -btn-mobile-wrapper d-lg-none">
                <div class="-inner-wrapper">
                  <a class="text-decoration-none">
                    <div class="-profile-name">{{ User.username }}</div>
                  </a>
                  <div class="-balance-container">
                    <div class="-text-username">
                      {{ User.username }}
                    </div>
                    <div class="-user-balance js-user-balance f-sm-6 f-7">
                      <div
                        class="-inner-box-wrapper ng-star-inserted"
                        tabindex="0"
                      >
                        <img
                          src="@/assets/newImg/ic-coin.png"
                          alt="customer image"
                          width="26"
                          height="21"
                          class="img-fluid -ic-coin"
                        ><span
                          id="customer-balance"
                          tabindex="0"
                        ><span
                          class="-amount"
                        > {{
                          Number(Math.abs(balance))
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ','),

                        }} </span></span>
                      </div><button
                        id="btn-customer-balance-reload"
                        type="button"
                        class="-btn-balance"
                      >
                        <i class="fas fa-sync-alt f-9" />
                      </button>
                    </div>
                  </div>
                </div>
                <router-link :to="{ name: 'profile' }">
                  <div class="x-profile-image"><img
                    src="@/assets/newImg/profile.jpg"
                    alt="profile"
                    width="40px"
                    height="40px"
                    class="rounded-circle border border-2"
                    style="vertical-align: initial;"
                  ></div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-sidebar
      id="sidebar-right"
      header-class="no-header"
      bg-variant="primary"
      right
      backdrop
      shadow
    >
      <div class="x-modal-account-menu-mobile pl-2 pr-2">
        <div class="-modal-profile-mobile">
          <div class="x-profile-image ng-star-inserted text-center">
            <img
              src="@/assets/newImg/profile.jpg"
              alt="customer image"
              class="img-fluid -profile-image rounded-circle border border-2"
            >
          </div>

          <div class="-balance-containers ng-star-inserted text-center">
            <div class="-text-username">
              {{ User.username }}
            </div>
            <div class="-user-balance js-user-balance f-sm-6 f-7">
              <div class="-inner-box-wrapper">
                <img
                  src="@/assets/newImg/ic-coin.png"
                  alt="customer image"
                  width="26"
                  height="21"
                  class="img-fluid -ic-coin"
                ><span id="customer-balance">
                  {{
                    Number(Math.abs(balance))
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ','),

                  }}</span>
              </div>
              <!-- <button
                id="btn-customer-balance-reload"
                type="button"
                class="-btn-balance"
              >
                <i class="fas fa-sync-alt f-9" />
              </button> -->
            </div>
          </div>
        </div>
        <div class="-lists-outer-wrapper">
          <ul class="navbar-navs">
            <li class="nav-item ng-star-inserted">
              <router-link
                :to="{ name: 'home'}"
              >
                <button
                  type="button"
                  class="nav-links js-close-account-sidebar"
                  tabindex="0"
                >
                  <img
                    src="@/assets/newImg/home.png"
                    alt="ic-menu-user"
                    class="-icon-image"
                    height="35"
                  ><span
                    class="-text-menu"
                  > หน้าหลัก </span>
                </button>
              </router-link>
            </li>
            <li class="nav-item ng-star-inserted">
              <router-link
                :to="{ name: 'profile'}"
              >
                <button
                  type="button"
                  class="nav-links js-close-account-sidebar"
                  tabindex="0"
                >
                  <img
                    src="@/assets/newImg/ic-menu-user.png"
                    alt="ic-menu-user"
                    class="img-fluid -icon-image"
                  ><span
                    class="-text-menu"
                  > ข้อมูลบัญชี </span>
                </button>
              </router-link>
            </li>
            <!-- <li class="nav-item -coupon ng-star-inserted">
              <router-link
                :to="{ name: 'deposit-fixed'}"
              >
                <button
                  type="button"
                  class="nav-links js-close-account-sidebar js-account-approve-aware"
                  tabindex="0"
                >
                  <img
                    src="@/assets/newImg/depo.png"
                    alt="ic-menu-coupon"
                    width="35px"
                    class="img-fluid -icon-image"
                  ><span
                    class="-text-menu"
                  > ฝากประจำ </span>
                </button>
              </router-link>
            </li> -->
            <li class="nav-item -coupon ng-star-inserted">
              <router-link
                :to="{ name: 'invite-friend'}"
              >
                <button
                  type="button"
                  class="nav-links js-close-account-sidebar js-account-approve-aware"
                  tabindex="0"
                >
                  <img
                    src="@/assets/newImg/affiliate.png"
                    alt="ic-menu-coupon"
                    width="35px"
                    class="img-fluid -icon-image"
                  ><span
                    class="-text-menu"
                  > สร้างรายได้ </span>
                </button>
              </router-link>
            </li>
            <li class="nav-item -join-promotion ng-star-inserted">
              <router-link
                :to="{ name: 'promotions'}"
              >
                <button
                  type="button"
                  class="nav-links js-close-account-sidebar"
                  tabindex="0"
                >
                  <img
                    src="@/assets/newImg/ic-header-menu-promotion.png"
                    width="35"
                    alt="ic-menu-promotion"
                    class="img-fluid -icon-image"
                  ><span class="-text-menu"> โปรโมชั่น </span>
                </button>
              </router-link>
            </li>
            <li class="nav-item -join-promotion ng-star-inserted">
              <router-link
                :to="{ name: 'lucky-wheel'}"
              >
                <button
                  type="button"
                  class="nav-links js-close-account-sidebar"
                  tabindex="0"
                >
                  <img
                    src="@/assets/newImg/wheel.png"
                    width="35"
                    alt="ic-menu-promotion"
                    class="img-fluid -icon-image"
                  ><span class="-text-menu"> หมุนกงล้อ </span>
                </button>
              </router-link>
            </li>
            <li class="nav-item -join-promotion ng-star-inserted">
              <router-link
                :to="{ name: 'deposit'}"
              >
                <button
                  type="button"
                  class="nav-links js-close-account-sidebar"
                  tabindex="0"
                >
                  <img
                    src="@/assets/newImg/ic-mobile-right-1.webp"
                    alt="ic-menu-promotion"
                    width="35"
                    class="img-fluid -icon-image"
                  ><span class="-text-menu"> ฝากเงิน </span>
                </button>
              </router-link>
            </li>
            <li class="nav-item -join-promotion ng-star-inserted">
              <router-link
                :to="{ name: 'withdraw'}"
              >
                <button
                  type="button"
                  class="nav-links js-close-account-sidebar"
                  tabindex="0"
                >

                  <img
                    src="@/assets/newImg/ic-mobile-right-2.webp"
                    alt="ic-menu-promotion"
                    width="35"
                    class="img-fluid -icon-image"
                  ><span class="-text-menu"> ถอนเงิน </span>

                </button>
              </router-link>
            </li>
            <li class="nav-item -logout ng-star-inserted">
              <button
                type="button"
                class="nav-links js-close-account-sidebar"
                @click="logout"
              ><img
                src="@/assets/newImg/ic-menu-logout.png"
                alt="ic-menu-logout"
                class="img-fluid -icon-image"
              ><span class="-text-menu"> ออกจากระบบ </span></button>
            </li>
          </ul>
        </div>
      </div>
    </b-sidebar>
  </nav>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import { initialAbility } from '@/libs/acl/config'
import { BSidebar, VBToggle } from 'bootstrap-vue'
import BUID from 'uniquebrowserid'

export default {
  components: {
    // Navbar Components
    // BImg,
    BSidebar,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => { },
    },
  },
  data() {
    return {
      agent: {},
      userData: JSON.parse(localStorage.getItem('userData')),
      balance: 0.0,
      turnpro: '',
      turn: '',
      User: {},
      logo_paths: '/logo/newjen.png',
      // logo_path: localStorage.getItem('logo_path'),
      // today: moment(new Date()).format('DD/MM/YYYY'),
      // timenow: moment(new Date()).format('HH:mm'),
    }
  },
  mounted() {
    this.GetAgentData()
    this.getbalance()
    this.updateBUID()
    this.GetUser(this.UserData.userID)
  },
  methods: {
    GetUser(userID) {
      this.$http
        .get(`/users/show/${userID}`)
        .then(response => {
          this.User = response.data
        })
        .catch(error => console.log(error))
    },
    updateBUID() {
      const buid = new BUID().completeID()
      const formData = {
        buid,
      }
      this.$http.post('/users/updatebuid', formData)
    },
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    },
    GetAgentData() {
      this.$http
        .get('/agent/show')
        .then(response => {
          // console.log(response.data)
          this.agent = response.data
        })
        .catch(error => console.log(error))
    },
    getbalance() {
      this.show = true
      this.$http
        .get('/balance/getbalance')
        .then(response => {
          // console.log(response.data)
          this.show = false
          this.User = response.data
          this.balance = response.data.balance
          if (this.balance < 0) {
            this.balance = 0
          }
          this.turn = response.data
        })
        .catch(error => console.log(error))
    },
  },
}
</script>

<style scoped>
.container {
  max-width: 600px;
}

.pd {
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 13px;
  color: #fff;
}

.bg2 {
  background-color: #000000;
  padding: 2px;
  border-radius: 0;
}

.bg {
  /* background-color: #101010 !important; */
  background: linear-gradient(180deg, #000000, #bdbdbd) !important;
  background-image: url(/back_bg.svg) !important;
  z-index: unset;
  /* border-bottom: 2px; */
  border-bottom-style: solid;
  border-image: linear-gradient(160deg,
      #b47e11,
      #fef1a2,
      #a54e07,
      #b47e11,
      #fef1a2,
      #bc881b,
      #a54e07) 1;
}

i {
  color: #ffc400;
}

/* .neon {
  margin-top: -12px;
  font-family: 'Helvethaica', sans-serif;

  border-radius: 5px;
  padding: 15px 10px;
} */
</style>
